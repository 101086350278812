
export const BASE_URL = 'https://api.iprayers.tajsoft.in/v1';

export const ADMIN_BASE_URL = BASE_URL + "/admin";

export const RESPONSE_STATUS = {
    ERROR: 415,
    SUCCESS: 200,
    OK: 200,
}

export const LOGIN_URL = ADMIN_BASE_URL + '/login';
