import { Datagrid, DateField, EditButton, List, NumberField, ReferenceField, TextField } from 'react-admin';

const MasjidList = () => {

    // const masjidFilters = [
    //     <TextInput name='MASJID_NAME' source="MASJID_NAME" label="Search" alwaysOn />,
    //     <ReferenceInput source="MASJID_ID" label="Masjid" reference="masjids" />,
    // ];

    return (
        <List >
            <Datagrid >
                <NumberField source="id" label="id" />
                <TextField source="MASJID_NAME" label="Name" />
                <TextField source="ADDRESS" label="Address" />
                <ReferenceField source="MASJID_CITY" target="CITY_ID" reference="cities" label="City" link="show" >
                    <TextField source="CITY_NAME" />
                </ReferenceField>
                <DateField source="FAJAR_JAMAT" label="Fajar" options={{
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: true
                    //dayPeriod: 'narrow',
                }}
                    showDate={false} showTime={true} transform={value => {
                        return new Date(`2024-07-09 ${value} `);
                    }} />
                <DateField source="ZUHAR_JAMAT" label="Zuhar"
                    options={{
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: true
                        //dayPeriod: 'narrow',
                    }}
                    showDate={false} showTime={true} transform={value => {
                        return new Date(`2024-07-09 ${value} `);
                    }} />
                <DateField source="ASAR_JAMAT" label="Asar"
                    options={{
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: true
                        //dayPeriod: 'narrow',
                    }}
                    showDate={false} showTime={true} transform={value => {
                        return new Date(`2024-07-09 ${value} `);
                    }} />
                <DateField source="MAGRIB_JAMAT" label="Magrib"
                    options={{
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: true
                        //dayPeriod: 'narrow',
                    }}
                    showDate={false} showTime={true} transform={value => {
                        return new Date(`2024-07-09 ${value} `);
                    }} />
                <DateField source="ISHA_JAMAT" label="Isha"
                    options={{
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: true
                        //dayPeriod: 'narrow',
                    }}
                    showDate={false} showTime={true} transform={value => {
                        return new Date(`2024-07-09 ${value} `);
                    }} />
                <DateField source="JUMA_JAMAT" label="Juma"
                    options={{
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: true
                        //dayPeriod: 'narrow',
                    }}
                    showDate={false} showTime={true} transform={value => {
                        return new Date(`2024-07-09 ${value} `);
                    }} />
                <DateField source="EIDUL_FITAR_JAMAT" label="Eidul Fitar"
                    options={{
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: true
                        //dayPeriod: 'narrow',
                    }}
                    showDate={false} showTime={true} transform={value => {
                        return new Date(`2024-07-09 ${value} `);
                    }} />
                <DateField source="EIDUL_DUHA_JAMAT" label="Eidul Duha"
                    options={{
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: true
                        //dayPeriod: 'narrow',
                    }}
                    showDate={false} showTime={true} transform={value => {
                        return new Date(`2024-07-09 ${value} `);
                    }} />
                <DateField source="MASJID_UPDATED" label="Updated At" />
                <DateField source="MASJID_CREATED" label="Created At" />
                <ReferenceField source="MASJID_UPDATED_BY" target="USER_ID" reference="users" label="Updated" link="show"  >
                    <TextField source="USER_NAME" />
                </ReferenceField>
                <ReferenceField source="MASJID_CREATED_BY" target="USER_ID" reference="users" label="Created" link="show" >
                    <TextField source="USER_NAME" />
                </ReferenceField>

                <EditButton />
            </Datagrid>
        </List>
    )
};
export default MasjidList;