import { useMediaQuery } from "@mui/material";
import { Datagrid, EditButton, List, NumberField, SimpleList, TextField } from 'react-admin';

const CountryList = () => {
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
    return (<List>
        {isSmall ?
            <SimpleList
                primaryText={(record) => record.COUNTRY_NAME}
                tertiaryText={(record) => record.COUNTRY_ID}
            />
            :
            <Datagrid>
                <NumberField source="COUNTRY_ID" label="id" />
                <TextField source="COUNTRY_NAME" label="Name" />
                <EditButton />
            </Datagrid>
        }
    </List>
    )
}
export default CountryList;
