import {
    Admin,
    Resource,
    localStorageStore,
    StoreContextProvider,
} from 'react-admin';
import authProvider from './auth/authProvider';
import PeopleIcon from '@mui/icons-material/People';
import MosqueIcon from '@mui/icons-material/Mosque';
import { dataProvider } from './service/dataProvider';
import MasjidList from './components/masjids/index';
import MasjidCreate from './components/masjids/masjidCreate';
import MasjidEdit from './components/masjids/masjidEdit';
import UserList from './components/users/index';
import UserEdit from './components/users/userEdit';
import UserCreate from './components/users/userCreate';
import CountryList from './components/countries/index';
import CountryEdit from './components/countries/editCountry';
import CountryCreate from './components/countries/createCountry';
import StateList from './components/states';
import StateCreate from './components/states/createState';
import StateEdit from './components/states/editState';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import MapIcon from '@mui/icons-material/Map';
import FlagIcon from '@mui/icons-material/Flag';
import CityList from './components/cities';
import CityCreate from './components/cities/cityCeated';
import CityEdit from './components/cities/cityEdit';
import Login from './auth/login';

const store = localStorageStore(undefined, 'iPrayers');

const App = () => {
    return (
        <Admin
            title="iPrayers"
            dataProvider={dataProvider}
            authProvider={authProvider}
            // dashboard={Dashboard}
            loginPage={Login}
            disableTelemetry
            defaultTheme="light"
        >
            <Resource name="masjids" list={MasjidList} icon={MosqueIcon} create={MasjidCreate} edit={MasjidEdit} />
            <Resource name="users" list={UserList} edit={UserEdit} create={UserCreate} icon={PeopleIcon} />
            <Resource name="cities" list={CityList} edit={CityEdit} create={CityCreate} icon={LocationCityIcon} />
            <Resource name="states" list={StateList} edit={StateEdit} create={StateCreate} icon={MapIcon} />
            <Resource name="countries" list={CountryList} edit={CountryEdit} create={CountryCreate} icon={FlagIcon} />
        </Admin>
    );
};

const AppWrapper = () => (
    <StoreContextProvider value={store}>
        <App />
    </StoreContextProvider>
);

export default AppWrapper;