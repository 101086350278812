import { useMediaQuery } from "@mui/material";
import { Datagrid, EditButton, List, NumberField, ReferenceField, SimpleList, TextField } from 'react-admin';

const StateList = () => {
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
    return (<List>
        {isSmall ?
            <SimpleList
                primaryText={(record) => record.COUNTRY_NAME}
                tertiaryText={(record) => record.COUNTRY_ID}
            />
            :
            <Datagrid>
                <NumberField source="STATE_ID" label="id" />
                <TextField source="STATE_NAME" label="Name" />
                <ReferenceField source="STATE_COUNTRY" target="COUNTRY_ID" reference="countries" label="Country" link="show" >
                    <TextField source="COUNTRY_NAME" />
                </ReferenceField>
                <EditButton />
            </Datagrid>
        }
    </List>
    )
}
export default StateList;
