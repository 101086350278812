import React from 'react';
import { Create } from 'react-admin';
import StateForm from './components/stateForm';

const initialValues = {
    STATE_NAME: "",
    STATE_COUNTRY: 0
}

export const StateCreate = (props) => {

    return (
        <Create {...props}>
            <StateForm initialValues={initialValues} />
        </Create>
    );
}

export default StateCreate;