import { useMediaQuery } from "@mui/material";
import { Datagrid, DateField, EditButton, EmailField, List, NumberField, SimpleList, TextField } from 'react-admin';

const UserList = () => {
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
    return (<List>
        {isSmall ?
            <SimpleList
                primaryText={(record) => record.USER_NAME}
                secondaryText={(record) => record.USER_EMAIL}
                tertiaryText={(record) => record.USER_UPDATED}
            />
            :
            <Datagrid>
                <NumberField source="USER_ID" label="id" />
                <TextField source="USER_NAME" label="Name" />
                <EmailField source="USER_EMAIL" label="Email" />
                <TextField source="USER_NUMBER" label="Number" />
                <TextField source="USER_ADDRESS" label="Address" />
                <DateField source="USER_CREATED" label="Created" />
                <DateField source="USER_UPDATED" label="Updated" />
                <EditButton/>
            </Datagrid>
        }
    </List>
    )
}
export default UserList;
