import React, { useCallback, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import * as Yup from 'yup';
import { Container } from "@mui/material";
import { useDataProvider } from 'react-admin';
import CustomFormikForm from '../../common/CustomFormikForm';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Field } from 'formik';

const CountrySchema = Yup.object().shape({
    COUNTRY_NAME: Yup.string().required('Name is required'),
});

const CountryForm = ({ initialValues, id }) => {

    // const [selectedCountry, setSelectedCountry] = useState(null);
    const [countriesList, setCountriesList] = useState([]);
    const dataProvider = useDataProvider();

    const fetchCountries = useCallback(() => {
        try {
            dataProvider.getList('countries', {}).then(response => {
                setCountriesList(response.data);
                // const initialCountry = response.data.find(country => country.COUNTRY_ID === initialValues.COUNTRY_ID);
                // setSelectedCountry(initialCountry || null);
            }).catch(error => {
                console.error(error);
            })
        } catch (error) {
            console.error(error);
        }
    }, [dataProvider]);

    useEffect(() => {
        fetchCountries();
    }, [fetchCountries])

    return (
        <Container>
            <CustomFormikForm initialValues={initialValues} validationSchema={CountrySchema}
                resource='countries' id={id}>
                <Grid item xs={12} sm={12} md={6}>
                    <Field name="COUNTRY_NAME">
                        {({ field, form }) => (
                            <Autocomplete
                                name='COUNTRY_NAME'
                                options={countriesList}
                                autoHighlight
                                autoSelect
                                value={countriesList.find(option => option.COUNTRY_NAME === field.value) || null}
                                onChange={(event, newValue) => {
                                    form.setFieldValue('COUNTRY_NAME', newValue ? newValue.COUNTRY_NAME : '');
                                }}
                                getOptionLabel={(option) => option?.COUNTRY_NAME ? option.COUNTRY_NAME : ''}
                                renderOption={(props, option) => {
                                    const { key, ...optionProps } = props;
                                    return (
                                        <Box
                                            key={option.id}
                                            name='COUNTRY_ID'
                                            component="li"
                                            sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                                            {...optionProps}
                                        >
                                            <img
                                                loading="lazy"
                                                width="20"
                                                srcSet={`https://flagcdn.com/w40/${option.COUNTRY_CODE.toLowerCase()}.png 2x`}
                                                src={`https://flagcdn.com/w20/${option.COUNTRY_CODE.toLowerCase()}.png`}
                                                alt=""
                                            />
                                            {option.COUNTRY_NAME}
                                        </Box>
                                    );
                                }}

                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Choose a country"
                                        name='COUNTRY_NAME'
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'Country Name', // disable autocomplete and autofill
                                        }}
                                    />
                                )}

                            />
                        )}
                    </Field>
                    {/* <CustomField name="COUNTRY_NAME" label="Name" /> */}
                </Grid>
            </CustomFormikForm>
        </Container>
    );
}

export default CountryForm;