import authProvider from '../auth/authProvider';
import { getDefaultHeaderData } from './payLoad';
import axios from 'axios';

const axiosInstance = axios.create();
axiosInstance.interceptors.response.use((response) => {
    return response;
}, async (error) => {
    return Promise.reject(error);
});

axiosInstance.interceptors.request.use(
    async (config) => {
        const defaultHeaderData = await getDefaultHeaderData();
        const updatedHeaderData = { ...defaultHeaderData, ...config.headers }
        config.headers = updatedHeaderData;
        return config;
    },
    error => {
        Promise.reject(error.response)
    }
);

export const getDataFromService = async (props) => {
    const { url, header = {}, params = {} } = props;
    return new Promise(async (resolve, reject) => {
        try {
            axiosInstance({
                method: 'get',
                url: url,
                params: params,
                headers: header
            }).then((response) => {
                resolve(response)
            }).catch((error) => {
                if (error?.response?.status === 401) {
                    authProvider.logout();
                }
                console.error(error);
                reject(error.response ? error.response : error)
            });
        } catch (error) {
            reject(error);
        }
    });
}

export const postDataFromService = async (props) => {
    const { url, header = {}, params = {}, data = {} } = props
    return new Promise(async (resolve, reject) => {
        try {
            axiosInstance({
                method: 'post',
                url: url,
                headers: header,
                params: params,
                data: data
            }).then((response) => {
                resolve(response)
            }).catch((error) => {
                if (error?.response?.status === 401) {
                    authProvider.logout();
                }
                console.error('post data', error);
                reject(error?.response ? error.response : error)
            });

        } catch (error) {
            console.error('try catch', error);
            reject(error)
        }
    });
}


export const deleteDataFromService = async (props) => {
    const { url, header = {}, params = {}, data = {} } = props
    return new Promise(async (resolve, reject) => {
        try {
            axiosInstance({
                method: 'delete',
                url: url,
                headers: header,
                params: params,
                data: data
            }).then((response) => {
                resolve(response)
            }).catch((error) => {
                if (error?.response?.status === 401) {
                    authProvider.logout();
                }
                reject(error.response ? error.response : error)
            });

        } catch (error) {
            reject(error)
        }
    });
}
