import { store } from "../store";

export const getDefaultHeaderData = async () => {
  const { offline = {} } = store.getState()
  const { userCredentials } = offline;
  let data = {
    'Content-Type': 'application/json',
    timeStamp: new Date().getTime(),
    brand: "iPrayers",
    Authorization: userCredentials,
  };

  return data;
};

