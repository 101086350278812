import AppReducer from '../reducers/AppReducer';
import storage from 'redux-persist/lib/storage/session';
import { getStoredState, persistReducer, persistStore } from 'redux-persist';
import { configureStore } from '@reduxjs/toolkit';
import { thunk } from 'redux-thunk';

// Middleware: Redux Persist Config
const persistConfig = {
    // Root
    key: 'root',
    storage: storage,

    // Whitelist (Save Specific Reducers)
    whitelist: [
        'offline',
    ],
    // Blacklist (Don't Save Specific Reducers)
    blacklist: [],
};

// Middleware: Redux Persist Persisted Reducer
const persistedReducer = persistReducer(persistConfig, AppReducer);

let store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    }).concat(thunk),
    preloadedState: {},
})

let persistor = persistStore(store);

const getStoredStore = async () => {
    let storedState = await getStoredState(persistConfig)
    return storedState;
}

export {
    store,
    persistor,
    getStoredStore
};