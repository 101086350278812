import React from 'react';
import { Create } from 'react-admin';
import MasjidForm from './components/MasjidForm';

const initialValues = {
    MASJID_NAME: "",
    MASJID_ADDRESS: '',
    MASJID_CITY: 0,
    FAJAR_JAMAT: '',
    ZUHAR_JAMAT: '',
    ASAR_JAMAT: '',
    MAGRIB_JAMAT: '',
    ISHA_JAMAT: '',
    JUMA_JAMAT: '',
    EIDUL_FITAR: '',
    EIDUL_DUHA: '',
    LATITUDE: '',
    LONGITUDE: ''
}

export const MasjidCreate = (props) => {

    return (
        <Create {...props}>
            <MasjidForm initialValues={initialValues} />
        </Create>
    );
}

export default MasjidCreate;