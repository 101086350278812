import { HttpError } from "react-admin";
import { deleteDataFromService, getDataFromService, postDataFromService } from ".";
import { ADMIN_BASE_URL } from "./config";

export const dataProvider = {
    getList: (resource, params) => {
        let query = {};
        if (params && params.length > 0) {
            const { page, perPage } = params?.pagination;
            const { field, order } = params?.sort;
            query = {
                sort: JSON.stringify([field, order]),
                range: JSON.stringify([(page - 1) * perPage, page * perPage]),
                filter: JSON.stringify(params.filter),
            };
        }
        // const url = `${apiUrl}/${resource}?${stringify(query)}`;

        return getDataFromService({ url: `${ADMIN_BASE_URL}/${resource}`, params: query })
            .then(response => {
                return Promise.resolve(response?.data ? response.data : response);
            }).catch(error => {
                console.error(error);
                return Promise.reject(new HttpError(error.statusText, error.status, { data: { id: params.id } }));
            })
    },

    getOne: (resource, params) => {
        return getDataFromService({ url: `${ADMIN_BASE_URL}/${resource}/${params.id}` })
            .then(response => {
                return Promise.resolve(response?.data ? response.data : response);
            }).catch(error => {
                console.error(error)
                return Promise.reject(new HttpError(error.statusText, error.status, { data: { id: params.id } }));
            })
    },

    getMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        return getDataFromService({ url: `${ADMIN_BASE_URL}/${resource}`, params: query })
            .then(response => {
                return Promise.resolve(response?.data ? response.data : response);
            }).catch(error => {
                console.error(error)
                return Promise.reject(new HttpError(error.statusText, error.status, { data: { id: params.id } }));
            })
    },

    getManyReference: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify({
                ...params.filter,
                [params.target]: params.id,
            }),
        };

        return getDataFromService({ url: `${ADMIN_BASE_URL}/${resource}`, params: query })
            .then(response => {
                return Promise.resolve(response?.data ? response.data : response);
            }).catch(error => {
                console.error(error)
                return Promise.reject(new HttpError(error.statusText, error.status, { data: { id: params.id } }));
            })
    },

    update: (resource, params) => {
        return postDataFromService({ url: `${ADMIN_BASE_URL}/${resource}/${params?.id ? params?.id : params?.data.id}`, data: params?.data })
            .then(response => {
                if (response.status === 200) {
                    return Promise.resolve(response)
                } else {
                    return Promise.reject(response);
                }
            }).catch(error => {
                return Promise.reject(new HttpError(error.statusText, error.status, { data: { id: params.id } }));
            })
    },
    updateMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params?.ids }),
        };
        return postDataFromService({ url: `${ADMIN_BASE_URL}/${resource}`, params: query, data: params?.data })
            .then(response => {
                return Promise.resolve(response)
            }).catch(error => {
                console.error(error);
                return Promise.reject(new HttpError(error.statusText, error.status, { data: { id: params.id } }));
            })
    },

    create: (resource, params) => {
        return postDataFromService({ url: `${ADMIN_BASE_URL}/${resource}/0`, data: params?.data })
            .then(response => {
                return Promise.resolve(response)
            }).catch(error => {
                console.error(error);
                return Promise.reject(new HttpError(error.statusText, error.status, { data: { id: 0 } }));
            })
    },

    delete: (resource, params) => {
        return deleteDataFromService({ url: `${ADMIN_BASE_URL}/${resource}/${params.id}` })
            .then(response => {
                return response.data;
            }).catch(error => {
                console.error(error);
                return Promise.reject(new HttpError(error.statusText, error.status, { data: { id: params.id } }));
            });
    },

    deleteMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        return deleteDataFromService({ url: `${ADMIN_BASE_URL}/${resource}`, params: query })
            .then(response => {
                return response.data;
            }).catch(error => {
                console.error(error);
                return Promise.reject(new HttpError(error.statusText, error.status, { data: { id: params.ids } }));
            })
    },
};