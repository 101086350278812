import React from 'react';
import { Datagrid, EditButton, List, NumberField, ReferenceField, ReferenceInput, TextField, TextInput } from 'react-admin';

const CityList = () => {

    const cityFilters = [
        <TextInput source="q" label="Search" alwaysOn />,
        <ReferenceInput source="CITY_NAME" label="City" reference="cities" />,
    ];

    return (
        <List filters={cityFilters}>
            <Datagrid >
                <NumberField source="id" label="id" />
                <TextField source="CITY_NAME" label="Name" />
                <ReferenceField source="CITY_STATE" target="STATE_ID" reference="states" label="State" link="show" >
                    <TextField source="STATE_NAME" />
                </ReferenceField>
                <EditButton />
            </Datagrid>
        </List>
    )
};
export default CityList;