import REDUX_CONSTANTS from "../utils/ReduxConstants";

export function updateCrdentials(credentials) {
    return dispatch => {
        dispatch({ type: REDUX_CONSTANTS.USER_CREDENTIALS, data: credentials })
    }
}

export function updateUser(user) {
    return dispatch => {
        dispatch({ type: REDUX_CONSTANTS.USER, data: user });
    }
}
