import React, { useCallback, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { CustomField } from '../../../theme/style';
import * as Yup from 'yup';
import { Container } from "@mui/material";
import { useDataProvider } from 'react-admin';
import CustomFormikForm from '../../common/CustomFormikForm';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Field } from 'formik';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import LeafletMap from '../../common/LeafletMap';
import { getMyPosition } from '../../../utils/AppHelper';
import { getFormRef } from '../../../service/FormikReference';

const MasjidSchema = Yup.object().shape({
    MASJID_NAME: Yup.string().required('Name is required'),
    ADDRESS: Yup.string().required('Address is required'),
    MASJID_CITY: Yup.number("Select city").required('City is required').min(1, 'City is required'),
    FAJAR_JAMAT: Yup.string().required('Fajar jamat time is required'),
    ZUHAR_JAMAT: Yup.string().required('Zuhar jamat time is required'),
    ASAR_JAMAT: Yup.string().required('Asar jamat time is required'),
    MAGRIB_JAMAT: Yup.string().required('Magrib jamat time is required'),
    ISHA_JAMAT: Yup.string().required('Isha jamat time is required'),
    JUMA_JAMAT: Yup.string().required('Juma jamat time is required'),
    EIDUL_FITAR_JAMAT: Yup.string().required('Eidul Fitar jamat time is required'),
    EIDUL_DUHA_JAMAT: Yup.string().required('Eidul Duha jamat time is required'),
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const MasjidForm = ({ initialValues, id }) => {
    const dataProvider = useDataProvider();

    const [citiesList, setCitiesList] = useState([]);
    const [latitude, setLatitude] = useState(initialValues?.LATITUDE);
    const [longitude, setLongitude] = useState(initialValues?.LONGITUDE);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const updateLatLng = ({ lat, lng }) => {
        const ref = getFormRef();
        ref.setFieldValue('LATITUDE', lat);
        ref.setFieldValue('LONGITUDE', lng);
        setLatitude(lat);
        setLongitude(lng);
    };

    useEffect(() => {
        if (!latitude || !longitude) {
            getMyPosition().then(data => {
                const { latitude: lat, longitude: lng } = data.coords;
                updateLatLng({ lat: lat, lng: lng });
            }).catch(err => {
                console.error(err);
            });
        }
    }, [])

    const fetchCities = useCallback(() => {
        try {
            dataProvider.getList('cities', {}).then(response => {
                setCitiesList(response.data);
            }).catch(error => {
                console.error(error);
            });
        } catch (error) {
            console.error(error);
        }
    }, [dataProvider]);

    useEffect(() => {
        fetchCities();
    }, [fetchCities])

    return (
        <Container>
            <CustomFormikForm initialValues={initialValues} validationSchema={MasjidSchema} resource={'masjids'} id={id}>
                <Grid item xs={12} sm={12} md={6}>
                    <CustomField name="MASJID_NAME" label="Name" />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <Field name="MASJID_CITY">
                        {({ field, form }) => (
                            <Autocomplete
                                freeSolo
                                name='MASJID_CITY'
                                options={citiesList}
                                autoHighlight
                                value={citiesList.find(option => option.CITY_ID === form.values.MASJID_CITY) || null}
                                onChange={(event, newValue) => {
                                    form.setFieldValue('MASJID_CITY', newValue ? newValue.CITY_ID : '');
                                }}
                                getOptionLabel={(option) => option?.CITY_NAME ? option.CITY_NAME : ''}
                                renderOption={(props, option) => {
                                    const { key, ...optionProps } = props;
                                    return (
                                        <Box
                                            key={option.id}
                                            name='MASJID_CITY'
                                            component="li"
                                            sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                                            {...optionProps}
                                        >
                                            {option.CITY_NAME}
                                        </Box>
                                    );
                                }}

                                renderInput={(params) => (
                                    <TextField
                                        variant='outlined'
                                        {...params}
                                        label="Choose a city"
                                        name='MASJID_CITY'
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'City', // disable autocomplete and autofill
                                        }}
                                        helperText={form.touched.MASJID_CITY && form.errors.MASJID_CITY}
                                        error={form.touched.MASJID_CITY && Boolean(form.errors.MASJID_CITY)}
                                    />
                                )}

                            />
                        )}
                    </Field>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <CustomField name="ADDRESS" label="Address" />
                </Grid>

                <Grid item xs={12} sm={12} md={6}>
                    <CustomField name="FAJAR_JAMAT" label="Fajar" type='time' />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <CustomField name="ZUHAR_JAMAT" label="Zuhar" type='time' />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <CustomField name="ASAR_JAMAT" label="Asar" type='time' />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <CustomField name="MAGRIB_JAMAT" label="Magrib" type='time' />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <CustomField name="ISHA_JAMAT" label="Isha" type='time' />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <CustomField name="JUMA_JAMAT" label="Juma" type='time' />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <CustomField name="EIDUL_FITAR_JAMAT" label="Eidul Fitar" type='time' />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <CustomField name="EIDUL_DUHA_JAMAT" label="Eidul Duha" type='time' />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <CustomField name="LATITUDE" label="Latitude" />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <CustomField name="LONGITUDE" label="Longitude" />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <Button onClick={handleOpen}>Set Masjid Location</Button>
                </Grid>
            </CustomFormikForm>
            <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Select Masjid Location
                        </Typography>
                        <Button autoFocus color="inherit" onClick={handleClose}>
                            save
                        </Button>
                    </Toolbar>
                </AppBar>
                <LeafletMap callback={updateLatLng} masjidName={initialValues.MASJID_NAME} masjidPostion={{ lat: latitude, lng: longitude }} />
            </Dialog>
        </Container>
    );
}

export default MasjidForm;