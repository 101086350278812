import React from 'react';
import { Edit, useEditController } from 'react-admin';
import UserForm from './components/UserForm';

export const UserEdit = (props) => {

    const { record } = useEditController();

    const transform = (data) => {
        const sanitizedData = {};
        for (const key in data) {
            if (typeof data[key] === "string" && data[key].trim().length === 0) continue;
            sanitizedData[key] = data[key];
        }
        return sanitizedData;
    };
    return (
        <Edit {...props} transform={transform} >
            <UserForm initialValues={record} id={record.USER_ID} />
        </Edit>
    );
}

export default UserEdit;