import React from 'react';
import { Field } from 'formik';
import TextField from '@mui/material/TextField';
import styled from "@emotion/styled";
import { alpha } from "@mui/material";

const ShadowedTextField = styled((props) => (
    <TextField {...props} />
))(({ theme }) => ({
    '& .MuiFormLabel-root': {
        fontSize: 18,
    },
    '& .MuiFormHelperText-root': {
        fontSize: 16,
    },
    '& .MuiFilledInput-root': {
        fontSize: 18,
        overflow: 'hidden',
        borderRadius: 4,
        backgroundColor: 'transparent',
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 1px`,
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
        '&:hover': {
            backgroundColor: 'transparent',
        },
        '&.Mui-focused': {
            backgroundColor: 'transparent',
            boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
            borderColor: theme.palette.primary.main,
        },
    },
}));

export const CustomField = ({ name, label, type = 'text', readOnly = false, onClick }) => (
    <Field name={name}>
        {({ field, form }) => (
            <ShadowedTextField
                onClick={onClick}
                {...field}
                value={form.values[name]}
                type={type}
                label={label}
                fullWidth
                onChange={(e) => {
                    form.setFieldValue(name, e.target.value);
                }}
                variant="outlined"
                error={form.touched[name] && Boolean(form.errors[name])}
                helperText={form.touched[name] && form.errors[name]}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                    readOnly: readOnly,
                    onClick: onClick
                }}
            />
        )}
    </Field>
);
