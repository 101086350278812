import React, { useCallback, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import * as Yup from 'yup';
import { Container } from "@mui/material";
import { useDataProvider } from 'react-admin';
import CustomFormikForm from '../../common/CustomFormikForm';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Field } from 'formik';

const CitySchema = Yup.object().shape({
    CITY_NAME: Yup.string().required('Name is required'),
    CITY_STATE: Yup.string().required('State is required'),
});

const StateForm = ({ initialValues, id }) => {

    // const [selectedCountry, setSelectedCountry] = useState(null);
    const [statesList, setStatesList] = useState([]);
    const [citiesList, setCitiesList] = useState([]);
    const dataProvider = useDataProvider();

    const fetchCities = useCallback(() => {
        try {
            dataProvider.getList('cities', {}).then(response => {
                setCitiesList(response.data);
            }).catch(error => {
                console.error(error);
            })
        } catch (error) {
            console.error(error);
        }
    }, [dataProvider]);

    useEffect(() => {
        fetchCities();
    }, [fetchCities])

    const fetchStates = useCallback(() => {
        try {
            dataProvider.getList('states', {}).then(response => {
                setStatesList(response.data);
            }).catch(error => {
                console.error(error);
            })
        } catch (error) {
            console.error(error);
        }
    }, [dataProvider]);

    useEffect(() => {
        fetchStates();
    }, [fetchStates])

    return (
        <Container>
            <CustomFormikForm initialValues={initialValues} validationSchema={CitySchema}
                resource='cities' id={id}>
                <Grid item xs={12} sm={12} md={6}>
                    <Field name="CITY_NAME">
                        {({ field, form }) => (
                            <Autocomplete
                                freeSolo
                                name='CITY_NAME'
                                options={citiesList}
                                autoHighlight
                                value={citiesList.find(option => option.CITY_NAME === field.value) || null}
                                onChange={(event, newValue) => {
                                    form.setFieldValue('CITY_NAME', newValue ? newValue.CITY_NAME : '');
                                }}
                                getOptionLabel={(option) => option?.CITY_NAME ? option.CITY_NAME : ''}
                                renderOption={(props, option) => {
                                    const { key, ...optionProps } = props;
                                    return (
                                        <Box
                                            key={option.id}
                                            name='CITY_NAME'
                                            component="li"
                                            sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                                            {...optionProps}
                                        >
                                            {option.CITY_NAMEs}
                                        </Box>
                                    );
                                }}

                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Choose a city"
                                        name='CITY_NAME'
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'City Name', // disable autocomplete and autofill
                                        }}
                                        onChange={(event) => { form.setFieldValue('CITY_NAME', event.target.value); }}
                                    />
                                )}

                            />
                        )}
                    </Field>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <Field name="CITY_STATE">
                        {({ field, form }) => (
                            <Autocomplete
                                name='CITY_STATE'
                                options={statesList}
                                autoHighlight
                                autoSelect
                                value={statesList.find(option => option.STATE_ID === field.value) || null}
                                onChange={(event, newValue) => {
                                    form.setFieldValue('CITY_STATE', newValue ? newValue.STATE_ID : '');
                                }}
                                getOptionLabel={(option) => option?.STATE_NAME ? option.STATE_NAME : ''}
                                renderOption={(props, option) => {
                                    const { key, ...optionProps } = props;
                                    return (
                                        <Box
                                            key={option.STATE_ID}
                                            name='CITY_STATE'
                                            component="li"
                                            sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                                            {...optionProps}
                                        >
                                            {option.STATE_NAME}
                                        </Box>
                                    );
                                }}

                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Choose a state"
                                        name='CITY_STATE'
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'State Name', // disable autocomplete and autofill
                                        }}
                                    />
                                )}

                            />
                        )}
                    </Field>
                </Grid>
            </CustomFormikForm>
        </Container>
    );
}

export default StateForm;