import React from 'react';
import { Edit, useEditController } from 'react-admin';
import MasjidForm from './components/MasjidForm';

export const MasjidEdit = (props) => {

    const { record } = useEditController();

    const transform = (data) => {
        const sanitizedData = {};
        for (const key in data) {
            if (typeof data[key] === "string" && data[key].trim().length === 0) continue;
            sanitizedData[key] = data[key];
        }
        return sanitizedData;
    };
    return (
        <Edit {...props} transform={transform} >
            <MasjidForm initialValues={record} id={record?.id} />
        </Edit>
    );
}

export default MasjidEdit;