import { combineReducers } from "redux";
import REDUX_CONSTANTS from "../utils/ReduxConstants";

const userCredentials = (state = null, action) => {
    switch (action.type) {
        case REDUX_CONSTANTS.USER_CREDENTIALS:
            return action.data
        default:
            return state;
    }
};

const userDetails = (state = null, action) => {
    switch (action.type) {
        case REDUX_CONSTANTS.USER:
            return action.data
        default:
            return state;
    }
};


export default combineReducers({
    userDetails,
    userCredentials
})