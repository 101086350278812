import React, { useCallback, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import * as Yup from 'yup';
import { Container } from "@mui/material";
import { useDataProvider } from 'react-admin';
import CustomFormikForm from '../../common/CustomFormikForm';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Field } from 'formik';

const StateSchema = Yup.object().shape({
    STATE_NAME: Yup.string().required('Name is required'),
    STATE_COUNTRY: Yup.string().required('Country is required'),
});

const StateForm = ({ initialValues, id }) => {

    // const [selectedCountry, setSelectedCountry] = useState(null);
    const [countriesList, setCountriesList] = useState([]);
    const [statesList, setStatesList] = useState([]);
    const dataProvider = useDataProvider();

    const fetchCountries = useCallback(() => {
        try {
            dataProvider.getList('countries', {}).then(response => {
                setCountriesList(response.data);
            }).catch(error => {
                console.error(error);
            })
        } catch (error) {
            console.error(error);
        }
    }, [dataProvider]);

    useEffect(() => {
        fetchCountries();
    }, [fetchCountries])

    const fetchStates = useCallback(() => {
        try {
            dataProvider.getList('states', {}).then(response => {
                setStatesList(response.data);
            }).catch(error => {
                console.error(error);
            })
        } catch (error) {
            console.error(error);
        }
    }, [dataProvider]);

    useEffect(() => {
        fetchStates();
    }, [fetchStates])

    return (
        <Container>
            <CustomFormikForm initialValues={initialValues} validationSchema={StateSchema}
                resource='states' id={id}>
                <Grid item xs={12} sm={12} md={6}>
                    <Field name="STATE_NAME">
                        {({ field, form }) => (
                            <Autocomplete
                                freeSolo
                                name='STATE_NAME'
                                options={statesList}
                                autoHighlight
                                value={statesList.find(option => option.STATE_NAME === field.value) || null}
                                onChange={(event, newValue) => {
                                    form.setFieldValue('STATE_NAME', newValue ? newValue.STATE_NAME : '');
                                }}
                                getOptionLabel={(option) => option?.STATE_NAME ? option.STATE_NAME : ''}
                                renderOption={(props, option) => {
                                    const { key, ...optionProps } = props;
                                    return (
                                        <Box
                                            key={option.id}
                                            name='STATE_ID'
                                            component="li"
                                            sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                                            {...optionProps}
                                        >
                                            {option.STATE_NAME}
                                        </Box>
                                    );
                                }}

                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Choose a state"
                                        name='STATE_NAME'
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'State Name', // disable autocomplete and autofill
                                        }}
                                        onChange={(event) => { form.setFieldValue('STATE_NAME', event.target.value); }}
                                    />
                                )}

                            />
                        )}
                    </Field>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <Field name="STATE_COUNTRY">
                        {({ field, form }) => (
                            <Autocomplete
                                name='STATE_COUNTRY'
                                options={countriesList}
                                autoHighlight
                                autoSelect
                                value={countriesList.find(option => option.COUNTRY_ID === field.value) || null}
                                onChange={(event, newValue) => {
                                    form.setFieldValue('STATE_COUNTRY', newValue ? newValue.COUNTRY_ID : '');
                                }}
                                getOptionLabel={(option) => option?.COUNTRY_NAME ? option.COUNTRY_NAME : ''}
                                renderOption={(props, option) => {
                                    const { key, ...optionProps } = props;
                                    return (
                                        <Box
                                            key={option.COUNTRY_ID}
                                            name='STATE_COUNTRY'
                                            component="li"
                                            sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                                            {...optionProps}
                                        >
                                            <img
                                                loading="lazy"
                                                width="20"
                                                srcSet={`https://flagcdn.com/w40/${option.COUNTRY_CODE.toLowerCase()}.png 2x`}
                                                src={`https://flagcdn.com/w20/${option.COUNTRY_CODE.toLowerCase()}.png`}
                                                alt=""
                                            />
                                            {option.COUNTRY_NAME}
                                        </Box>
                                    );
                                }}

                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Choose a country"
                                        name='STATE_COUNTRY'
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'Country Name', // disable autocomplete and autofill
                                        }}
                                    />
                                )}

                            />
                        )}
                    </Field>
                </Grid>
            </CustomFormikForm>
        </Container>
    );
}

export default StateForm;