import React from 'react';
import { Formik, Form } from 'formik';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { useDataProvider, useNotify, useRedirect } from 'react-admin';
import { setFormRef } from '../../service/FormikReference';


const CustomFormikForm = ({ children, initialValues, validationSchema, resource, id }) => {
    const notify = useNotify();
    const redirect = useRedirect();
    const dataProvider = useDataProvider();

    const handleSubmit = async (values, { setSubmitting }) => {
        try {

            const url = resource;
            let response;
            if (id) {
                response = await dataProvider.update(url, { id, data: values });
            } else {
                response = await dataProvider.create(url, { id, data: values });
            }

            setSubmitting(false);
            notify('Saved successfully', { type: 'success' });
            redirect(`/${url}/${response?.data?.id}`);
            setSubmitting(false);

        } catch (error) {
            setSubmitting(false);
            notify(error.message, { type: 'error' });
        }
    };

    return (
        <Formik
            innerRef={(f) => { setFormRef(f) }}
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {({ isSubmitting, handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                    <Grid container spacing={2} sx={{ p: 2 }}>
                        {children}
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};

export default CustomFormikForm;