import React from 'react';
import { Create } from 'react-admin';
import CountryForm from './components/countryForm';

const initialValues = {
    COUNTRY_NAME: "",
}

export const CountryCreate = (props) => {

    return (
        <Create {...props}>
            <CountryForm initialValues={initialValues} />
        </Create>
    );
}

export default CountryCreate;