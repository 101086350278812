import React from 'react';
import Grid from '@mui/material/Grid';
import { CustomField } from '../../../theme/style';
import * as Yup from 'yup';
import { Container } from "@mui/material";
import CustomFormikForm from '../../common/CustomFormikForm';

const UserSchema = Yup.object().shape({
    USER_NAME: Yup.string().required('User name is required'),
    USER_EMAIL: Yup.string().email().required('Password is required'),
});

const UserForm = ({ save, initialValues, id }) => {
    return (
        <Container>
            <CustomFormikForm save={save} initialValues={initialValues} validationSchema={UserSchema} resource='users' id={id}>
                <Grid item xs={12} sm={12} md={6}>
                    <CustomField name="USER_NAME" label="Name" />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <CustomField name="USER_EMAIL" label="Email" type="email" />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <CustomField name="USER_NUMBER" label="Mobile Number" />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <CustomField name="USER_ADDRESS" label="Address" />
                </Grid>
            </CustomFormikForm>
        </Container>
    );
}

export default UserForm;