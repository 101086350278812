import React from 'react';
import { Edit, useEditController } from 'react-admin';
import CityForm from './components/CityForm';

export const CityEdit = (props) => {

    const { record } = useEditController();

    const transform = (data) => {
        const sanitizedData = {};
        for (const key in data) {
            if (typeof data[key] === "string" && data[key].trim().length === 0) continue;
            sanitizedData[key] = data[key];
        }
        return sanitizedData;
    };

    return (
        <Edit {...props} transform={transform} >
            <CityForm initialValues={record} id={record?.CITY_ID} />
        </Edit>
    );
}

export default CityEdit;