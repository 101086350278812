import React from 'react';
import { Create } from 'react-admin';
import UserForm from './components/UserForm';

const initialValues = {
    USER_NAME: "",
    USER_EMAIL: '',
    USER_NUMBER: '',
    USER_ADDRESS: ''
}

export const UserCreate = (props) => {

    return (
        <Create {...props}>
            <UserForm initialValues={initialValues} />
        </Create>
    );
}

export default UserCreate;