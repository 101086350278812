import { updateCrdentials, updateUser } from "../actions/offline";
import { postDataFromService } from "../service";
import { store } from "../store";
import { LOGIN_URL } from "../service/config";
import { HttpError } from "react-admin";

const authProvider = {
    login: (props) => {
        console.log('LOGIN_URL', LOGIN_URL);
        return postDataFromService({ url: LOGIN_URL, data: props })
            .then(response => {
                store.dispatch(updateCrdentials(response?.data?.token));
                store.dispatch(updateUser(response?.data?.user))
                return Promise.resolve(response?.statusText);
            }).catch(error => {
                console.error('login', error);
                return Promise.reject(new HttpError(error.statusText, error.status));
            })
    },
    logout: () => {
        store.dispatch(updateCrdentials(null));
        return Promise.resolve();
    },
    checkError: () => Promise.resolve(),
    checkAuth: () => {
        const { offline = {} } = store.getState()
        const { userCredentials } = offline;
        return userCredentials ? Promise.resolve() : Promise.reject();
    },
    getPermissions: () => {
        const { offline = {} } = store.getState()
        const { userCredentials } = offline;
        return userCredentials ? Promise.resolve() : Promise.reject();
    },
    getIdentity: () => {
        const { offline = {} } = store.getState()
        const { userDetails } = offline;

        return Promise.resolve({
            id: userDetails?.userId,
            fullName: userDetails?.name,
        })
    }
};

export default authProvider;
