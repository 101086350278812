import React, { useEffect, useState } from "react";
import './index.css'
import { MapContainer, Marker, Popup, TileLayer, useMap, useMapEvents } from "react-leaflet";
import masjidMarkerIcon from '../../assets/marker.png'
import "leaflet.locatecontrol/dist/L.Control.Locate.min.css"; // Import styles
import L from 'leaflet'
import 'leaflet/dist/leaflet.css';
import 'leaflet.locatecontrol/dist/L.Control.Locate.css';
import "leaflet.locatecontrol"; // Import plugin

const RecenterAutomatically = ({ lat, lng }) => {
    const map = useMap();
    useEffect(() => {
        if (map)
            map.panTo([lat, lng]);
    }, [lat, lng, map]);
    return null;
}

const MapCLickPosition = ({ callback }) => {
    useMapEvents({
        click(e) {
            if (callback)
                callback(e.latlng);
        },
    });
    return null;
};

const LocateControl = () => {
    const map = useMap();

    useEffect(() => {
        const locateControl = L.control.locate({
            // position: 'topright',
            drawCircle: true,
            setView: true,
            keepCurrentZoomLevel: true,
            showPopup: true,
            strings: {
                title: "Show me where I am",
            },
            // locateOptions: {
            //     maxZoom: 18,
            //     enableHighAccuracy: true
            // }
            onLocationFound: function (e) {
                console.log(e.latlng);
                RecenterAutomatically(e.latlng);
                // map.setView(e.latlng, 18);  // Ensure the map centers on the current location
            },
            // icon: markerIconPng
        });
        locateControl.addTo(map);

        // Cleanup on unmount
        return () => {
            map.removeControl(locateControl);
        };
    }, [map]);

    return null;
};


const LeafletMap = ({ masjidPostion, masjidName, callback }) => {

    const [mapCenter, setMapCenter] = useState({ lat: 0, lng: 0 });
    const [position, setPosition] = useState(masjidPostion);

    useEffect(() => {
        setMapCenter(position);
        if (callback) {
            if (position.lat && position.lng)
                callback(position)
        }
    }, [position, callback])


    const moveMasjidMarker = (position) => {
        setPosition(position)
    }

    const MasjidMarker = ({ position, name }) => {
        const masjidIcon = L.icon({
            iconUrl: masjidMarkerIcon,
            iconSize: [60, 60],
            iconAnchor: [20, 65],
            popupAnchor: [-10, -10]
        });

        return (<Marker position={position} icon={masjidIcon}>
            <Popup>{name ? name : 'Masjid Location'}</Popup>
        </Marker>)
    }

    return (<MapContainer
        center={mapCenter}
        zoom={18}
        scrollWheelZoom={true}>
        <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <MasjidMarker position={position} name={masjidName} />
        <RecenterAutomatically lat={mapCenter.lat} lng={mapCenter.lng} />
        <MapCLickPosition callback={moveMasjidMarker} />
        <LocateControl /> 
    </MapContainer>);
}
export default LeafletMap;