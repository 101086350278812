import React from 'react';
import { Create } from 'react-admin';
import CityForm from './components/CityForm';

const initialValues = {
    CITY_NAME: "",
    CITY_STATE: '',
}

export const CityCreate = (props) => {

    return (
        <Create {...props}>
            <CityForm initialValues={initialValues} />
        </Create>
    );
}

export default CityCreate;